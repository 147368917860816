<template>
  <quill-editor @ready="onEditorReady($event)" @editorChange="sendContent" @textChange="sendContent" :options="options" style="height:200px;"></quill-editor>
</template>

<script>
//Imports editor de texto
  import Quill from "quill"
  import { QuillEditor } from '@vueup/vue-quill'
  
  const Clipboard = Quill.import('modules/clipboard')
  const Delta = Quill.import('delta')

  //Limpia el formato del texto cuando se pega en el editor
  class PlainClipboard extends Clipboard {
    onPaste (e) {
      e.preventDefault()
      const range = this.quill.getSelection()
      const text = e.clipboardData.getData('text/plain')
      const delta = new Delta()
      .retain(range.index)
      .delete(range.length)
      .insert(text)
      const index = text.length + range.index
      const length = 0
      this.quill.updateContents(delta, 'silent')
      this.quill.setSelection(index, length, 'silent')
      this.quill.scrollIntoView()
    }
  }

  Quill.register('modules/clipboard', PlainClipboard, true)

  import QuillImageUploader from 'quill-image-uploader'
  //Registrar modulo de subida de imagenes
  Quill.register("modules/imageUploader", QuillImageUploader)

  //funcion para extraer e insertar imagen a el editor y enviarla a la api
  export function imageHandler() {
    const quill = this.quill

    const input = document.createElement('input')

    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()

    input.onchange = async () => {
      const file = input.files[0]
      const formData = new FormData()

      formData.append('image', file)

      // Save current cursor state
      const range = quill.getSelection(true)

      // Insert temporary loading placeholder image
      quill.insertEmbed(range.index, 'image', 'https://cdn.dribbble.com/users/1341307/screenshots/5377324/morph_dribbble.gif')

      // Move cursor to right side of image (easier to continue typing)
      quill.setSelection(range.index + 1)
      // THIS NEEDS TO BE HOOKED UP TO AN API
      // RESPONSE DATA WILL THEN BE USED TO EMBED THE IMAGE
      // const res = await apiPostNewsImage(formData)

      // Remove placeholder image
      quill.deleteText(range.index, 1)

      try {
        //console.info('before')
        /*const res = await axios({
            method: 'POST',
            url: 'https://api.imgur.com/3/image',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: formData
        })
        console.info('res', res)*/
      } catch (err) {
        console.error('error', err)
      }
      // Insert uploaded image
      quill.insertEmbed(range.index, 'image', 'https://www.gravatar.com/avatar/f3ee4bcc7ffc1da7441cc3c95393b2c1?s=80')
    }
  }
export default {
  name: 'RichEditor',
  components:{ QuillEditor },
  props:{
    options: Object,
    text: String
  },
  emits:[ "content" ],
  data(){
    return {
      editor: null
    }
  },
  methods:{
    onEditorReady(editor) {
      this.editor = editor
    },
    sendContent(){
      this.$emit("content", this.editor.root.innerHTML)
    }
  },
  created(){
    this.unwatchIsLiveProp = this.$watch('text', () => {
      if (this.text) {
        this.editor.root.innerHTML = this.text
        this.$nextTick(() => {
          let q = this.editor
          q.setSelection(this.text.length, 0, 'api')
          q.focus()
        })
        this.unwatchIsLiveProp()
      }
    })
  }
}
</script>
<style src="@vueup/vue-quill/dist/vue-quill.snow.css"></style>
<style>
  .ql-align-right {
    text-align: right;
  }
  .ql-align-center {
    text-align: center;
  }
  .ql-align-left {
    text-align: left;
  }
  .ql-video {
    display: block;
    max-width: 100%;
  }
  .ql-video.ql-align-center {
    margin: 0 auto
  }
  .ql-video.ql-align-right {
    margin: 0 0 0 auto
  }
</style>